@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*------------------------------------*\
  # VARIABLES
\*------------------------------------*/
/*------------------------------------*\
  # FONTS
\*------------------------------------*/
.poppins {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.montserrat-light {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

.montserrat-medium {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

/*------------------------------------*\
  # PLACEHOLDER CLASSES
\*------------------------------------*/
.file-uploader, .select-input, .button {
  border-radius: 0;
  border: 1px solid rgb(5, 54, 84);
  font-size: 1em;
  padding: 3px 7px;
  color: rgb(5, 54, 84);
  background-color: #fff;
}

.title-container .title, .project-page .gallery-container .view-gallery {
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.choices-container .choices, .navbar-expanded .navbar-content nav ul li a, .navbar nav ul li a {
  color: rgb(5, 54, 84);
}
.choices-container .choices:hover, .navbar-expanded .navbar-content nav ul li a:hover, .navbar nav ul li a:hover {
  font-weight: 600;
  color: #f3961c;
}

.projects-slides h2, .previews .preview-text h2 {
  padding-bottom: 0.8em;
  font-size: calc(0.9em + 0.5vw);
  line-height: 1.2em;
}

/*------------------------------------*\
  # GLOBAL SELECTORS
\*------------------------------------*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: rgb(5, 54, 84);
}

a {
  text-decoration: none;
  color: #f3961c;
}
a:hover {
  text-decoration: none;
}

p {
  padding-top: 1.5em;
}

ul {
  list-style-type: none;
}

img {
  image-rendering: -webkit-optimize-contrast !important;
}

/*------------------------------------*\
    # UTILITY CLASSES 
  \*------------------------------------*/
.container {
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1530px;
}

.main-page {
  min-height: calc(100vh - 60px - 26px);
}
@media screen and (max-width: 550px) {
  .main-page {
    min-height: calc(100vh - 60px - 37px);
  }
}

.content {
  padding-top: 15vh;
  padding-bottom: 10vh;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.pointer {
  cursor: pointer;
}

.button:hover {
  color: #fff;
  background-color: rgb(5, 54, 84);
}

.choices-container {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
}
.choices-container .choices {
  margin-right: 5vw;
  display: block;
  font-size: 1.1em;
}
@media screen and (max-width: 768px) {
  .choices-container .choices {
    font-size: calc(0.65em + 1vw);
  }
}

.selected {
  color: #f3961c !important;
  font-weight: 600;
}

.hover-opaque:hover {
  opacity: 0.6;
}

.opaque {
  opacity: 0.8;
}

.scroll-up {
  position: -webkit-sticky;
  position: sticky;
  margin-left: auto;
  bottom: 0;
  padding: 0 5px;
  width: 30px;
  height: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.pt-large {
  padding-top: 20vh;
}

.pt-small {
  padding-top: 10vh;
}

.pb-small {
  padding-bottom: 2em;
}

.pb-large {
  padding-bottom: 4em;
}

.w-100 {
  width: 100%;
}

.space-around {
  justify-content: space-around;
}

/*------------------------------------*\
    # NAVBAR
  \*------------------------------------*/
.navbar-expanded {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.95);
}
.navbar-expanded .navbar-content {
  padding: 0 30px 5px 30px;
  flex-direction: column;
}
.navbar-expanded .navbar-content nav ul {
  flex-direction: column;
}
.navbar-expanded .navbar-content nav ul li {
  margin-top: 20px;
  text-align: center;
}
.navbar-expanded .navbar-content nav ul li a {
  font-size: 1.1em;
}
.navbar-expanded .navbar-content img {
  height: 1em;
  margin-top: 40px;
}

.navbar {
  height: 60px;
  padding: 0 30px 5px 30px;
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 0 7px rgba(5, 54, 84, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.navbar .logo {
  display: flex;
  align-items: flex-end;
}
.navbar .logo img {
  max-height: 50px;
}
.navbar .button-open {
  height: 50%;
}
.navbar nav {
  margin-bottom: 10px;
}
.navbar nav ul {
  display: flex;
}
.navbar nav ul li a {
  margin-left: 20px;
  font-size: 0.8em;
}

/*------------------------------------*\
  # FORMS
\*------------------------------------*/
form label {
  display: block;
  margin-bottom: 0.6em;
}
form input,
form textarea {
  margin-top: 5px;
  margin-bottom: 2rem;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(5, 54, 84);
  padding: 2px 0;
  font-size: 0.9em;
  color: rgb(5, 54, 84);
}
form input:focus,
form textarea:focus {
  outline: none;
}
form input {
  line-height: 2px;
}
form textarea {
  resize: vertical;
}
form .invalid {
  border-bottom: 2px solid #f3961c;
}

/*------------------------------------*\
  # ERROR SCREEN
\*------------------------------------*/
.error h1 {
  font-size: 3em;
}

/*------------------------------------*\
  # HOME PAGE
\*------------------------------------*/
.title-container {
  padding: 30px 30px 4em 30px;
  position: relative;
  color: #fff;
}
.title-container .title h1 {
  display: block;
  color: #ebeff0;
  font-size: 4.82vw;
  font-weight: 200;
}
@media screen and (max-width: 1300px) {
  .title-container .title h1 {
    font-size: 6vw;
  }
}
@media screen and (max-width: 900px) {
  .title-container .title h1 {
    font-size: 8vw;
  }
}
.title-container .title h2 {
  font-size: 2.25vw;
}
@media screen and (max-width: 1300px) {
  .title-container .title h2 {
    font-size: 2.8vw;
  }
}
@media screen and (max-width: 900px) {
  .title-container .title h2 {
    font-size: 3.7vw;
  }
}

.projects-slides {
  padding: 30px;
}
.projects-slides .slide {
  padding: 5px;
}
.projects-slides h2 {
  text-align: center;
}
@media screen and (min-width: 900px) {
  .projects-slides h2 {
    font-size: 1.6;
  }
}

.previews .preview-text {
  padding: 15px 0;
}
.previews .preview-text p {
  padding-top: 0.8em;
  font-size: calc(0.7em + 0.2vw);
}
@media screen and (min-width: 901px) {
  .previews .preview-text {
    padding-top: 4em;
  }
  .previews .preview-text h2 {
    font-size: calc(1.4em + 0.5vw);
  }
}
@media screen and (min-width: 901px) and (max-width: 980px) {
  .previews .preview-text {
    padding-top: 1em;
  }
}
.previews .preview-img .slick-prev {
  z-index: 1;
}
.previews .preview-img .slick-prev img {
  position: absolute;
  top: 50%;
  left: 30px;
}
.previews .preview-img .slick-next img {
  position: absolute;
  top: 50%;
  right: 30px;
}
@media screen and (min-width: 901px) {
  .previews .profile-preview {
    display: grid;
    grid-template-columns: 54% 46%;
  }
  .previews .profile-preview .profile-preview-text {
    padding-left: 30px;
  }
  .previews .projects-preview {
    display: grid;
    grid-template-columns: 46% 54%;
  }
  .previews .projects-preview .projects-preview-img {
    order: 1;
  }
  .previews .projects-preview .projects-preview-text {
    text-align: right;
    padding-right: 30px;
  }
}

.home-logo {
  padding: 2em 0;
  margin: 0 30px;
}
.home-logo img {
  width: 70%;
  max-width: 500px;
}

/*------------------------------------*\
  # PROFILE PAGE
\*------------------------------------*/
.profile {
  padding-top: 15vh;
  padding-bottom: 10vh;
}
@media screen and (max-width: 900px) {
  .profile {
    padding-top: 7vh;
  }
  .profile .grid {
    grid-template-columns: 1fr;
  }
}
.profile .profile-image img {
  width: 100%;
  display: block;
}
@media screen and (min-width: 500px) and (max-width: 900px) {
  .profile .profile-image img {
    width: 70%;
  }
}
@media screen and (min-width: 1200px) {
  .profile .profile-image img {
    width: 40vh;
  }
}
.profile .profile-logo {
  width: 50%;
}
@media screen and (max-width: 900px) {
  .profile .profile-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
  }
  .profile .profile-logo img {
    width: 70%;
  }
}
.profile p {
  line-height: 1.4em;
  text-align: justify;
}
.profile p a {
  color: #f3961c;
}
.profile p strong {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.profile .curriculum-link {
  padding-top: 1.5em;
  display: block;
  text-align: right;
}

/*------------------------------------*\
    # PROJECTS SCREEN AND PROJECT PAGE
  \*------------------------------------*/
.project-container {
  margin: 0 30px;
  padding: 0;
  max-width: 1530px;
}
@media screen and (min-width: 901px) {
  .project-container {
    margin: 0 217px;
  }
}

.projects {
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 4em;
  margin-top: 1.2em;
}
@media screen and (max-width: 1200px) {
  .projects {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 500px) {
  .projects {
    grid-template-columns: 1fr;
  }
}
.projects .projects-text h1 {
  padding-top: 10px;
  font-size: calc(0.8em + 1vw);
}
@media screen and (min-width: 900px) {
  .projects .projects-text h1 {
    font-size: calc(0.8em + 0.5vw);
  }
}
.projects .projects-text h2 {
  font-size: 0.9em;
  padding-bottom: 1em;
  padding-top: 0.8em;
}
@media screen and (max-width: 768px) {
  .projects .projects-text h2 {
    font-size: calc(0.5em + 1vw);
  }
}

.pswp__top-bar {
  font-family: "Poppins", sans-serif;
}

.project-page {
  padding-top: 30px;
  padding-bottom: 10vh;
}
.project-page h1 {
  padding-bottom: 0.8em;
  font-size: 2em;
}
.project-page p {
  text-align: justify;
  padding: 0.7em 0;
  line-height: 1.5em;
}
.project-page .gallery-container {
  position: relative;
}
@media screen and (min-width: 900px) {
  .project-page .gallery-container {
    width: 70%;
    margin: 0 auto;
  }
}
.project-page .gallery-container .view-gallery {
  top: 50%;
  display: block;
  color: #ebeff0;
  font-size: 3em;
}

/*------------------------------------*\
  # CONTACTS PAGE 
\*------------------------------------*/
.contacts .grid {
  padding-top: 1.5em;
}
@media only screen and (max-width: 900px) {
  .contacts .grid {
    grid: none;
  }
  .contacts .grid form {
    padding-top: 10vh;
  }
}
.contacts .contacts-logo {
  margin-bottom: 3em;
}
.contacts .contacts-logo img {
  width: 16em;
}
.contacts .contacts-icons {
  max-width: 10em;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5em;
}
.contacts .contacts-icons a {
  display: block;
  width: 1.5em;
}
.contacts .contacts-icons img {
  max-width: 100%;
}
.contacts .consent {
  font-size: 0.7em;
  justify-content: flex-start;
  align-items: baseline;
  padding-bottom: 2em;
}
.contacts .consent input {
  display: none;
}
.contacts .consent .custom-checkbox {
  height: 1em;
  width: 1em;
  border: 1px solid rgb(5, 54, 84);
  margin-right: 1em;
}
.contacts .consent .invalid {
  border: 1px solid #f3961c;
}
.contacts .consent .valid {
  background-color: rgb(5, 54, 84);
}
.contacts .consent .invalid-label {
  color: #f3961c;
}

/*------------------------------------*\
  # PRIVACY POLICY SCREEN
\*------------------------------------*/
.privacy h3 {
  padding-top: 40px;
}
.privacy ul {
  padding-top: 1.5em;
  padding-left: 1em;
}

/*------------------------------------*\
    # FOOTER
  \*------------------------------------*/
footer {
  display: flex;
  justify-content: space-between;
  font-size: calc(0.5em + 0.2vw);
  padding: 5px 30px;
  margin-top: auto;
}
footer p,
footer a {
  padding: 2px 0;
  color: rgba(5, 54, 84, 0.75);
}
@media screen and (max-width: 550px) {
  footer {
    display: grid;
    grid-template-columns: 4fr 1fr;
  }
  footer .privacy-link {
    text-align: right;
  }
}

/*------------------------------------*\
  # ADMIN LOGIN PAGE 
\*------------------------------------*/
.login form {
  width: 80%;
}
.login form .error-message {
  padding-bottom: 20px;
  color: #f3961c;
}
@media only screen and (min-width: 500px) {
  .login form {
    max-width: 400px;
  }
}

/*------------------------------------*\
    # PROJECT FORM & DASHBOARD
  \*------------------------------------*/
.dashboard {
  margin-top: 20px;
}
.dashboard h1 {
  font-weight: 500;
  font-size: 2em;
  padding-bottom: 1em;
}
@media only screen and (max-width: 700px) {
  .dashboard .grid {
    grid: none;
  }
}
.dashboard .project-card .button-order,
.dashboard .project-card .button-delete {
  margin-top: 10px;
}
.dashboard .project-card h1 {
  font-size: 1.2em;
  padding: 10px 0;
}

.select-container {
  display: flex;
  align-items: flex-end;
}
.select-container label {
  margin-bottom: 0;
}
.select-container .select-input {
  margin-left: 10px;
}

.file-uploader {
  width: 30%;
  display: inline;
}
.file-uploader:hover {
  color: #fff;
  background-color: rgb(5, 54, 84);
}
.file-uploader input {
  display: none;
}

.file-form {
  margin-bottom: 20px;
  padding-bottom: 20px;
  width: 100%;
}
.file-form p {
  padding-bottom: 1em;
}
.file-form img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}
.file-form .grid {
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.images-grid .thumbnail {
  grid-column: 1/3;
  width: 50%;
  margin: auto;
  max-width: 450px;
}
@media screen and (min-width: 1500px) {
  .images-grid {
    grid-template-columns: repeat(4, 1fr);
  }
  .images-grid .thumbnail {
    grid-column: 1/5;
  }
}

.image-form-buttons {
  min-width: 400px;
}

/*------------------------------------*\
    # MESSAGES TABLE
  \*------------------------------------*/
.messages {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  cursor: pointer;
}
.messages .row {
  margin: 5px 2px;
  padding-bottom: 5px;
}
.messages .row-span {
  grid-column: 1/-1;
}
.messages .separator {
  border-bottom: 1px solid rgb(5, 54, 84);
}
@media only screen and (max-width: 768px) {
  .messages {
    grid: none;
  }
  .messages .row {
    margin: 2px;
    padding-bottom: 2px;
  }
  .messages .title {
    display: none;
  }
}

/*# sourceMappingURL=main.css.map */

